import { importAll } from '../utils/util'
/*
 * 抛出用export default
 * 参考routeContainer 内文件
 */
var allRoute
let throwRoute = []
// switch (front) {
//   case 'insp_cental_leader':
    allRoute = importAll(require.context('./routeContainer/routerMoudel', true, /\.js$/))
    for (const key of Object.keys(allRoute)) {
      throwRoute = [...throwRoute, ...allRoute[key].default]
    }
//   break
//   case 'produce':
//     allRoute = importAll(require.context('./routeContainer/routerMoudel', true, /\.js$/))
//     for (const key of Object.keys(allRoute)) {
//       throwRoute = [...throwRoute, ...allRoute[key].default]
//     }
//     break
//   default:
//     break
// }
export default throwRoute
