

const state = {
  authoritieList: []
}

const mutations = {
  SET_AUTHORITIE_LIST: (state, authoritieList) => {
    state.authoritieList = authoritieList
  }
}

const actions = {
  setAuthorities({ commit }, authoritieList){
    commit('SET_AUTHORITIE_LIST', authoritieList)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
