import { asyncRoutes, constantRoutes } from '../../router'

/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
function hasPermission (roles, route) {
  if (route.meta && route.meta.roles) {
    return roles.some(role => route.meta.roles.includes(role))
  } else {
    return true
  }
}

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */
export function filterAsyncRoutes (routes, roles) {
  const res = []

  routes.forEach(route => {
    const tmp = { ...route }
    if (hasPermission(roles, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, roles)
      }
      res.push(tmp)
    }
  })

  return res
}
export function judgeHasPermission (authorities, route) {
  if (route.meta && route.meta.authority) {
    return authorities.some(item => route.meta.authority === item.authority)
  } else {
    return true
  }
}
export function filterAsyncRoutesByPermission (routes, authorities) {
  const res = []
  routes.forEach(route => {
    const tmp = { ...route }
    if (judgeHasPermission(authorities, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutesByPermission(tmp.children, authorities)
      }
      res.push(tmp)
    }
  })
  return res
}

const state = {
  mainRoute: null,
  routes: [],
  addRoutes: []
}

const mutations = {
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes
    state.routes = constantRoutes.concat(routes)
  },
  SET_MAIN_ROUTE: (state, path) => {
    localStorage.setItem('mainRoute', path)
    state.mainRoute = path
  },
}

const actions = {
  generateRoutes ({ commit }, authorities) {
    return new Promise(resolve => {
      var accessedRoutes = asyncRoutes || []
      accessedRoutes = filterAsyncRoutesByPermission(asyncRoutes, authorities)
      // console.log('asyncRoutes', asyncRoutes)
      // accessedRoutes = filterAsyncRoutes(asyncRoutes, authorities)
      // if (roles.includes('admin')) {
      //   accessedRoutes = asyncRoutes || []
      // } else {
      //   accessedRoutes = filterAsyncRoutes(asyncRoutes, roles)
      // }
      const mainRoute = accessedRoutes.find(i => i.path.indexOf('*') === -1);
      if(mainRoute){
        let mainPath = mainRoute.path
        if (mainRoute.children && !!mainRoute.children.length) {
          mainPath = mainRoute.children[0].path
        }
        commit('SET_MAIN_ROUTE', mainPath)
      }
      commit('SET_ROUTES', accessedRoutes)
      resolve(accessedRoutes)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
