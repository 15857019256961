import Vue from 'vue'
import {
  Menu, MenuItem, Submenu, Form, FormItem, Input, Upload, RadioGroup, RadioButton,
  Button, Breadcrumb, BreadcrumbItem, DatePicker, Table, TableColumn, Popover, Tag, Checkbox,
  CheckboxGroup, Dialog, Row, Col, Select, Option, Pagination, Tabs, TabPane, Radio, Loading, Avatar,
  InputNumber, Switch, Dropdown, DropdownMenu, DropdownItem, TimePicker, Image, Cascader, Container, Aside,
  MenuItemGroup, Header, Main, Tree, Tooltip, Icon
} from 'element-ui'
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(Submenu)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Upload)
Vue.use(RadioGroup)
Vue.use(RadioButton)
Vue.use(Button)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(DatePicker)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Popover)
Vue.use(Tag)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Dialog)
Vue.use(Row)
Vue.use(Col)
Vue.use(Select)
Vue.use(Option)
Vue.use(Pagination)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Radio)
Vue.use(Loading)
Vue.use(Avatar)
Vue.use(InputNumber)
Vue.use(Switch)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(TimePicker)
Vue.use(Image)
Vue.use(Cascader)
Vue.use(Container)
Vue.use(Aside)
Vue.use(MenuItemGroup)
Vue.use(Header)
Vue.use(Main)
Vue.use(Tree)
Vue.use(Tooltip)
Vue.use(Icon)
 

 


