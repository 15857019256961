<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
body{
  margin: 0;
}
// 如果是整个页面的滚动条风格是一致的，直接改全局的滚动条样式也可以有效果
//滚动条的宽度
::-webkit-scrollbar {
width: 5px;
height: 5px;
}
//滚动条的滑块
::-webkit-scrollbar-thumb {
background-color: #a1a3a9;
border-radius: 3px;
}
.el-message-box{
  width: 360px!important;
}
// .el-tree-node__content .icons{margin-top: 0px;}
</style>
