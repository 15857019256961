import Vue from 'vue'
import store from '@/store'
import VueRouter from 'vue-router'
import allRouter from './importAll'
const Homepage = () => import('@/components/homepage/Homepage')
// import { GetUserPages } from '@/service/login/login.js'
Vue.use(VueRouter);
export const constantRoutes = [
    { path: '/', redirect: '/login'},
    {
      path: '/404',
      component: () => import('@/views/error-page/404'),
      hidden: true
    },
    {
      path: '/login',
      name: 'login',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '@/views/login.vue')
    },
    {
      path: '/',
      component: Homepage,
      children: [
        {
          path: '/home',
          meta: {
            title: '首页',
            name: 'home',
          },
          component: () => import(/* webpackChunkName: "commodity" */ '@/views/home.vue')
        },
        {
          path: '/promotion/channel',
          component: () => import('@/views/promotion/channel'),
          name: '渠道列表',
          meta: {
              title: '渠道列表',
          }
        },

        {
            path: '/promotion/role',
            component: () => import('@/views/promotion/role'),
            name: '推广角色',
            meta: {
                title: '推广角色',
            }
        },

        {
            path: '/promotion/overview',
            component: () => import('@/views/promotion/overview'),
            name: '推广概览',
            meta: {
                title: '推广概览',
            }
        },
        {
            path: '/promotion/channelRole',
            component: () => import('@/views/promotion/channelRole'),
            name: '渠道角色管理',
            meta: {
                title: '渠道角色管理',
            }
        },
        
      ]
    },
   
  ]

let asyncRoutesMap = [
  { path: '*', redirect: '/404', hidden: true }
]

asyncRoutesMap = [...asyncRoutesMap, ...allRouter]
export const asyncRoutes = asyncRoutesMap

const createRouter = () => new VueRouter({
  // mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

const router = createRouter()
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

router.beforeEach(async (to, from, next) => {
  console.log(to, from)
  if(!localStorage.getItem('accesstoken') && to.path !== '/login'){
    next(`/login`)
  }else{
    const vuexLoginStatus = store.getters.authoritieList !== undefined && store.getters.authoritieList.length != 0
    if (vuexLoginStatus) {
      next()
    } else {
      if(localStorage.getItem('accesstoken') && from.path !== '/login'){
        // GetUserPages().then(async (elementres) => {
          let elementres = []
          if(elementres){
            if(elementres.length === 0){
              elementres =[{"id":0,"code":"all","name":"all","type":0,"pid":0}]
            }
            let authorities = elementres
            authorities.map(item=>{
              item.authority = item.code
            })
            store.dispatch('user/setAuthorities',authorities)
            let accessRoutes = await store.dispatch(
              'permission/generateRoutes',
              authorities
            )
            router.addRoutes(accessRoutes)
          }
          next({ ...to, replace: true })
        // }).catch(err => {
        //   console.log(err)
        // })
      } else {
        next()
      }
    }
  }
})


export default router
